import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import { Container, Row, Col } from 'react-grid-system';

import CallToAction  from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Dropcap       from '@interness/web-core/src/components/text/Dropcap/Dropcap';
import HeaderImage   from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';

function ServicePage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading>Service</Heading>
          <h3 style={{ textAlign: 'center' }}>Perfekter Service vom Fachmann</h3>
          <p style={{ textAlign: 'justify' }}>Kompletter Service ist für unser Team selbstverständlich. Darum werden
            Uhren und Schmuck in unserer eigenen Werkstatt repariert, umgearbeitet oder angefertigt. So erfüllen wir
            Ihre Wünsche schnell, sicher und mit persönlichem Ansprechpartner.</p>
        </section>
        <Separator/>
        <section>
          <Container>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Goldschmiedereparaturen</h3></Dropcap>
                <ul>
                  <li>Goldschmiedereparaturen aller Art</li>
                  <li>Ringgrößen ändern</li>
                  <li>Perlenketten neu fassen oder knoten</li>
                  <li>Trauringumarbeitung</li>
                  <li>Neuanfertigungen</li>
                  <li>Schmuck reinigen und aufarbeiten</li>
                  <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                </ul>
              </Col>
              <Col md={6}>
                <Dropcap><h3>Armbanduhren</h3></Dropcap>
                <ul>
                  <li>mechanische Uhren / Handaufzug</li>
                  <li>Automatikuhren</li>
                  <li>Quarzarmbanduhren</li>
                  <li>Batteriewechsel</li>
                  <li>Wasserdichtigkeitsprüfung</li>
                  <li>kompletten Revision</li>
                  <li>Taschenuhren</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Dropcap><h3>Großuhren</h3></Dropcap>
                <ul>
                  <li>Heim – und Tischuhren, Jahresuhren</li>
                  <li>Standuhren</li>
                  <li>Regulator, Kuckucksuhren</li>
                  <li>GehäuseReparaturen und Aufarbeitung</li>
                </ul>
              </Col>
              <Col md={6}>
                <Dropcap><h3>Abholservice</h3></Dropcap>
                <ul>
                  <li>Wussten Sie schon, dass wir Ihre große, schwere Standuhr auch bei Ihnen zu Hause abholen ? Fragen Sie uns nach unserem Abhol– und Bring- sowie Montageservice.</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default ServicePage;

export const query = graphql`
    query ServicePageQuery {
        headerImage: file(relativePath: {eq: "headers/service.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;